import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import tw from "twin.macro";

const CalculatorContainer = styled.div`
    ${tw`max-w-md mx-auto p-8 bg-white rounded-lg shadow-md`}
    border: 2px solid #0ABD19;
    margin-top: 48px;
    margin-bottom: 8%;

    @media (max-width: 768px) {
        ${tw`mt-8 p-4`}
    }
`;

const Label = styled.label`
    ${tw`block mt-4 text-lg`}
`;

const Input = styled.input`
    ${tw`w-full p-2 border-b-2 border-gray-300 mt-2 focus:outline-none`}
    border: none;
    border-bottom: 2px solid #adb5bd;
    font-size: 20px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield; /* Firefox */
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const HalfWidthInput = styled.input`
    ${tw`w-full p-2 border-b-2 border-gray-300 mt-2 focus:outline-none`}
    border: none;
    border-bottom: 2px solid #adb5bd;
    font-size: 20px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield; /* Firefox */
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const Button = styled.button`
    ${tw`w-full p-2 mt-4 text-white bg-green-600 rounded`}
    text-decoration: none;
    border: none;
    border-radius: 10px;
    background-color: #0ABD19 !important;

    &:hover {
        background-color: #0ABD50;
    }

    &:focus {
        background-color: #0ABD50;
        outline: none;
    }
`;

const ResultContainer = styled.div`
    ${tw`mt-4 bg-white rounded`}
`;

const ResultFieldContainer = styled.div`
    ${tw`flex flex-wrap justify-between items-center`}
`;

const ResultField = styled.div`
    ${tw`w-full lg:w-auto flex-1 mx-2`}
`;

const CurrencyCalculator = () => {
    const [cost, setCost] = useState("");
    const [weight, setWeight] = useState("");
    const [exchangeRate, setExchangeRate] = useState(null);
    const [totalCost, setTotalCost] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await axios.get("https://www.cbr-xml-daily.ru/daily_json.js");
                setExchangeRate(response.data.Valute.USD.Value);
            } catch (error) {
                setError("Не удалось получить курс валют");
            }
        };
        fetchExchangeRate();
    }, []);

    const calculateTotalCost = (event) => {
        event.preventDefault();
        if (!cost || !weight) {
            alert("Пожалуйста, заполните все поля.");
            return;
        }

        const costNum = parseFloat(cost);
        const weightNum = parseFloat(weight);

        // Добавляем надбавку 4% к курсу ЦБ
        const adjustedExchangeRate = exchangeRate * 1.04;

        const deliveryCost = (15 / 100 * costNum) + (18 * weightNum);
        const total = deliveryCost * adjustedExchangeRate;

        setTotalCost(total.toFixed(2));
    };

    return (
        <CalculatorContainer>
            <form onSubmit={calculateTotalCost}>
                <Label>Введите стоимость товара в $ </Label>
                <Input
                    type="number"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                />
                <Label>Введите вес товара в кг</Label>
                <Input
                    type="number"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                />
                <Button type="submit">Рассчитать стоимость</Button>
                <ResultContainer>
                    <ResultFieldContainer>
                        <ResultField>
                            <Label>Общая сумма к оплате</Label>
                            <HalfWidthInput type="text" value={totalCost} readOnly />
                        </ResultField>
                        <ResultField>
                            <Label>Курс ЦБ на <br/>сегодня </Label>
                            <HalfWidthInput type="text" value={exchangeRate ? (exchangeRate * 1.04).toFixed(2) : ''} readOnly />
                        </ResultField>
                    </ResultFieldContainer>
                </ResultContainer>
            </form>
            {error && <p>{error}</p>}
        </CalculatorContainer>
    );
};

export default CurrencyCalculator;
