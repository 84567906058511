import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full text-center`;
const StepsContainer = styled.div`
    ${tw`flex flex-row justify-center items-stretch relative flex-wrap lg:flex-nowrap`}
    margin-bottom: 70px; /* Add more bottom margin */
`;

const Step = styled.div`
    ${tw`w-full lg:w-1/5 mt-16 text-left px-4 rounded-lg relative pt-2 flex flex-col mx-4`}
    ${props =>
            css`
                background: #ddf2eb; // Light green background
                box-shadow: none; // Remove border
                min-height: 200px; /* Set a minimum height for the steps */
                display: flex;
                flex-direction: column;
                justify-content: space-between; /* Ensure even spacing within the step */
            `}
`;

const StepHeader = styled.div`
    ${tw`flex flex-col uppercase leading-relaxed w-full`}
    .step-number {
        ${tw`font-bold text-2xl text-green-500`} /* Green step number and larger font size */
    }
    .step-title {
        ${tw`font-bold text-xl text-gray-900 mt-2`} /* Step title color and margin-top for spacing */
    }
`;

const StepDescription = styled.div`
    ${tw`mt-4 flex-grow w-full`} /* Allow the description to grow to fill available space */
    ${css`
        font-size: medium;
        color: #2d2d2d; /* Dark gray color for descriptions */
    `}
`;

const HighlightedText = styled.span`
    ${tw`font-bold`}
    color: #0ABD19;
`;

const DescriptionSteps = styled.span`
    ${tw`lg:my-3 text-base xl:text-lg w-full`}
    color: #243E63;
`;

const StepFooter = styled.div`
    ${tw`mt-4 w-full`}
    ${css`
        height: 2px;
        background-color: #0abd19; /* Green line */
    `}
`;

export default ({ heading = "Как это работает?", steps = null }) => {
    const defaultSteps = [
        {
            number: <HighlightedText>Шаг 1</HighlightedText>,
            title: "Заявка",
            description: <DescriptionSteps>Заполните заявку на нашем сайте или свяжитесь с нами по телефону</DescriptionSteps>,
        },
        {
            number: <HighlightedText>Шаг 2</HighlightedText>,
            title: "Покупка",
            description: <DescriptionSteps>Заказывайте товары или мы выкупим для вас, отправляйте к нам на склад</DescriptionSteps>,
        },
        {
            number: <HighlightedText>Шаг 3</HighlightedText>,
            title: "Оформление",
            description: <DescriptionSteps>Оформляйте доставку в Россию к себе или своим клиентам</DescriptionSteps>
        },
        {
            number: <HighlightedText>Шаг 4</HighlightedText>,
            title: "Получение",
            description: <DescriptionSteps>Получайте ваши товары на нашем складе или мы доставим до адресата в любой город России </DescriptionSteps>,
        },
    ];

    if (!steps) steps = defaultSteps;

    return (
        <Container>
            <ContentWithPaddingXl>
                <HeaderContainer>
                    <Heading>{heading}</Heading>
                </HeaderContainer>
                <StepsContainer>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepHeader>
                                <span className="step-number">{step.number}</span>
                                <span className="step-title">{step.title}</span>
                            </StepHeader>
                            <StepDescription>{step.description}</StepDescription>

                        </Step>
                    ))}
                </StepsContainer>
            </ContentWithPaddingXl>
        </Container>
    );
};
