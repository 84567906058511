import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Column = tw.div`flex flex-col items-center w-full`; // Ensure full width for Column

const FAQSContainer = tw.dl`w-full relative mt-0 flex flex-col items-center`; // Ensure full width for FAQSContainer and center items

const FAQ = styled.div`
    ${tw`cursor-pointer select-none mt-5 px-8 py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300 w-full`}
    max-width: 1050px;
    border-radius: 10px; // Added border radius
`;

const Question = styled.dt`
    ${tw`flex justify-between items-center w-full`}
    max-width: 1050px;
`;

const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-left`; // Removed w-full here to allow flexible width

const QuestionToggleIcon = motion(styled.span`
    ${tw`mr-4 transition duration-300 flex-shrink-0`}
    svg {
        ${tw`w-6 h-6`}
    }
`);

const Answer = motion(styled.dd`
    ${tw`pointer-events-none text-sm sm:text-base leading-relaxed w-full text-left`}
    margin-left: 0; // Align answer with question
    max-width: 1050px;
`);

export default ({
                    subheading = "FAQS",
                    heading = "You have Questions ?",
                    description = "And we have got answers to all of them. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    faqs = [
                        {
                            question: "Is lunch provided free of cost ?",
                            answer:
                                "Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system."
                        },
                        {
                            question: "Do you have 2 Bedroom suites ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        },
                        {
                            question: "Are Wi-Fi costs included in the price ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        },
                        {
                            question: "Where can I reach you for support ?",
                            answer:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        }
                    ]
                }) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    return (
        <Container>
            <ContentWithPaddingXl>
                <Column>
                    <FAQSContainer>
                        {faqs.map((faq, index) => (
                            <FAQ
                                key={index}
                                onClick={() => {
                                    toggleQuestion(index);
                                }}
                                className="group"
                            >
                                <Question>
                                    <QuestionText>{faq.question}</QuestionText>
                                    <QuestionToggleIcon
                                        variants={{
                                            collapsed: { rotate: 0 },
                                            open: { rotate: -180 }
                                        }}
                                        initial="collapsed"
                                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                        transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        <ChevronDownIcon />
                                    </QuestionToggleIcon>
                                </Question>
                                <Answer
                                    variants={{
                                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                                    }}
                                    initial="collapsed"
                                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    {faq.answer}
                                </Answer>
                            </FAQ>
                        ))}
                    </FAQSContainer>
                </Column>
            </ContentWithPaddingXl>
        </Container>
    );
};
