import React from "react";
import styled from 'styled-components';
import FAQs from './components/faqs/SingleCol';

const Layout = styled.div`
    overflow: hidden;
    margin: 20px;
    width: 100%; /* Changed from 70% to 100% */
    @media (max-width: 1024px) {
        margin: 10px;
    }
`;

const ContentContainer = styled.div`
    width: 100%; /* Ensure it takes the full width */
`;

export default function FAQComponent() {
    return (
        <Layout>
            <ContentContainer>
                <FAQs faqs={[
                    {
                        question: "Какие услуги склада вы предоставляете?",
                        answer: "Мы предоставляем адрес склада, получение посылок с товарами или крупногабаритные грузы, погрузка или разгрузка груза, регистрация в системе, хранение, переупаковка, консолидация в одно отправление."
                    },
                    {
                        question: "Как я узнаю, что моя посылка или груз были получены на вашем складе?",
                        answer: "Наш менеджер отправит вам сообщение о получении груза на складе с трек-номером и фотоотчет."
                    },
                    {
                        question: "Какие сроки хранения груза?",
                        answer: "Сроки хранения груза составляют 90 дней."
                    },
                    {
                        question: "Как вы доставляете ваши грузы?",
                        answer: "Все грузы доставляются авиадоставкой до Москвы."
                    },
                    {
                        question: "Возможно доставить груз в другой город России?",
                        answer: "Да, мы можем доставить ваш груз в любой город России."
                    },
                    {
                        question: "Вы предоставляете услуги по таможенному оформлению?",
                        answer: "Да, наша фирма сама проводит таможенное оформление грузов, доставленных в Россию."
                    },
                    {
                        question: "Какие сроки доставки груза до вашего склада?",
                        answer: "Все зависит от того, откуда был отправлен груз и какой службой доставки вы воспользовались. Обычно все грузы доставляются от 3-10 дней, включая грузы из третьих стран."
                    }
                ]} />
            </ContentContainer>
        </Layout>
    );
}
