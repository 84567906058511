import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SinglePage from "./SinglePageApplication";
import GlobalStyles from './styles/GlobalStyles';

// Locate the root element from the DOM
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);  // Create a root.

// Render your component tree using the root
root.render(

    <Router>
        <GlobalStyles />
        <Routes>

            <Route path="/" element={<SinglePage/>} />
        </Routes>
    </Router>
);