import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled, { createGlobalStyle } from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "components/headers/SingleLight";
import Header2 from "components/headers/SinglePageHeader";
import FeatureWithSteps from "components/features/SinglePageExp";
import ContactForm from "components/faqs/SingleContacts";
import ContactDetails from "components/cards/ThreeColContactDetails";
import Slider2 from "components/cards/SinglePageInformation";
import PartnersSlider from "components/features/PartnersSlider";
import Calculator from "components/features/SinglePageCalculator";
import FAQs from "components/faqs/SingleCol";
import Features from "components/features/ThreeColWithSideImage";
import FAQComponent from "./Questions";
import Dev from "images/SingleDeliv.svg";
import BackgroundVideo from "components/features/BackgroundVideo";
import Warehouse from "images/warehouseImage.svg";
import Receiving from "images/Receiving.svg";
import DeliveryS from "images/DeliverySingle.svg";
import Map from "images/kz-01.svg";
import Customs from "images/CustomClearance.svg";
import Transfer from "images/TransferToClient.svg";
import useScript from "components/features/CustomHook";
import { SectionHeading } from "./components/misc/Headings";
import "./CSS/IncludedServicesSectionTariff.css";

const GlobalStyle = createGlobalStyle`
    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;
const Container = styled.div`
    ${tw`max-w-6xl mx-auto px-4 py-8`}
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const TwoColumn = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto`}
    @media (max-width: 768px) {
    ${tw`gap-8`}
}
`;

const LeftColumn = styled.div`
    ${tw`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`}
    @media (max-width: 1024px) {
    ${tw`w-full`}
}
`;

const RightColumn = styled.div`
    ${tw`relative mt-8 lg:mt-0 flex-1 flex flex-col justify-center mb-8`}
    @media (max-width: 768px) {
    ${tw`mt-4 mb-4`}
}
`;

const Heading = styled.h1`
    ${tw`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`}
    margin-bottom: 30px;
    color: #0ABD19;
    @media (max-width: 1024px) {
        ${tw`text-3xl`}
    }
    @media (max-width: 768px) {
        ${tw`text-2xl`}
        margin-bottom: 20px;
    }
`;

const Paragraph = styled.p`
    ${tw`my-5 lg:my-8 text-base xl:text-lg`}
    line-height: 1.8;
    color: #243E63;
    letter-spacing: 0.5px;
    max-width: 100%;
    @media (max-width: 1024px) {
        ${tw`text-sm`}
    }
`;

const Actions = styled.div`
    ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
    button {
        ${tw`w-[200px] sm:relative sm:right-0 sm:top-0 sm:bottom-0 bg-green-500 text-white font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:leading-none focus:outline-none transition duration-300`}
        background-color: #0ABD19;
        border: none;
        &:hover,
        &:focus {
            background-color: #0ABD50;
            transform: scale(1.1);
        }
        @media (max-width: 768px) {
            ${tw`w-full`}
        }
    }
`;

const IllustrationContainer = styled.div`
    ${tw`flex justify-center lg:justify-end items-center`}
    margin-left: 9%;
    @media (max-width: 1024px) {
        margin-left: 0;
    }
`;

const CustomersLogoStrip = styled.div`
    ${tw`mt-12 lg:mt-20`}
    p {
        ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
    }
    img {
        ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
    }
`;

const HeadingFor = tw(SectionHeading)`text-center mt-4 font-black text-3xl sm:text-4xl lg:text-5xl leading-tight`;

const HighlightedText = styled.span`
    ${tw`font-bold`}
    color: #0ABD19;
`;

const ImageSection = styled.div`
    ${tw`relative w-full`}
`;

const Spacer = styled.div`
    ${tw`my-8`}
`;

const LargerSpacer = styled.div`
    ${tw`my-32`}
`;

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;

const Info = tw.span`text-sm mt-1 block text-gray-500`;

const BackgroundImage = styled.div`
    width: 100vw;
    margin-left: calc(50% - 50vw);
    background-image: url(${Dev});
    background-size: cover;
    background-position: bottom center;
    height: 60vh;
`;

const TextOverlay = styled.div`
    ${tw`absolute top-0 left-0 w-full h-full flex flex-col justify-start p-8 lg:p-24`}
    @media (max-width: 768px) {
    ${tw`items-start`}
}
`;

const OverlayHeading = styled.h2`
    ${tw`text-3xl lg:text-5xl font-bold mb-4`}
    margin-top: 2rem;
    color: #0ABD19;
    @media (max-width: 1024px) {
        ${tw`text-4xl`}
    }
    @media (max-width: 768px) {
        ${tw`text-2xl`}
    }
    @media (max-width: 480px) {
        ${tw`text-xl`}
    }
`;

const AimHeading = styled.h2`
    ${tw`text-3xl lg:text-5xl font-bold mb-4`}
    margin-top: 2rem;
    margin-left: 6%;
    color: #0ABD19;
    @media (max-width: 1024px) {
        ${tw`text-4xl`}
        margin-right: 24%;
    }
    @media (max-width: 768px) {
        ${tw`text-2xl`}
    }
    @media (max-width: 480px) {
        ${tw`text-xl`}
    }
`;

const OverlayText = styled.p`
    ${tw`text-lg lg:text-xl font-semibold my-1`}
    line-height: 1.2;
    color: #243E63;
    margin-left: 6%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
        text-align: left;
        width: 100%;
    }
    @media (max-width: 480px) {
        ${tw`text-sm`}
    }
`;

const PartnersSection = styled.div`
    ${tw`w-full flex flex-col items-center py-16 bg-white`}
`;

const PartnersHeading = styled.h2`
    ${tw`text-3xl lg:text-5xl font-bold text-gray-800 mb-8 text-center`}
`;

const ServicesSection = styled.div`
    ${tw`w-full flex flex-col items-center py-16 bg-white`}
`;

const ServiceItems = styled.div`
    ${tw`flex flex-row flex-wrap justify-center items-center mt-8 w-full max-w-screen-xl`}
    gap: 45px;
    @media (max-width: 768px) {
        ${tw`flex-col gap-4`}
    }
`;

const ServiceItem = styled.div`
    ${tw`flex flex-col items-center mx-4 my-4`}
`;

const ServiceImage = styled.img`
    ${tw`w-24 h-24`}
`;

const ServiceText = styled.p`
    ${tw`mt-4 text-center text-lg font-semibold`}
    color: #243E63;
`;

const TextContainer = styled.div`
    ${tw`max-w-full mx-auto p-8`}
    max-width: 70%;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

const BackgroundVideoWrapper = styled.div`
    margin-bottom: 60px;
    @media (max-width: 768px) {
        margin-bottom: 2%;
    }
`;

const CalculatorContainer = styled.div`
    ${tw`max-w-md mx-auto p-8 bg-white rounded-lg shadow-md`}
    border: 2px solid #0ABD19;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
    height: auto;
    @media (max-width: 768px) {
        ${tw`mt-8 p-4`}
    }
`;

const Label = styled.label`
    ${tw`block mt-4 text-lg`}
`;

const Input = styled.input`
    ${tw`w-full p-2 border-b-2 border-gray-300 mt-2 focus:outline-none`}
    border: none;
    border-bottom: 2px solid #adb5bd;
    font-size: 20px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const HalfWidthInput = styled.input`
    ${tw`w-full p-2 border-b-2 border-gray-300 mt-2 focus:outline-none`}
    border: none;
    border-bottom: 2px solid #adb5bd;
    font-size: 24px;
    padding: 10px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const Button = styled.button`
    ${tw`w-full p-2 mt-4 text-white bg-green-600 rounded`}
    text-decoration: none;
    border: none;
    border-radius: 10px;
`;

const ResultContainer = styled.div`
    ${tw`mt-4 bg-white rounded`}
`;

const ResultFieldContainer = styled.div`
    ${tw`flex flex-wrap justify-between mt-2`}
`;

const ResultField = styled.div`
    ${tw`w-full lg:flex-1 lg:mx-3`}
`;

const InputRow = styled.div`
    ${tw`flex flex-wrap justify-between mt-2`}
`;

const TariffCardsContainer = styled.div`
    ${tw`flex flex-wrap justify-center items-center gap-4 relative mx-auto`}
    width: 60%;
`;

const TariffCard = styled.div`
    ${tw`p-8 bg-white rounded-lg shadow-md relative mt-12 mx-4`}
    border: 2px solid #0ABD19;
    background-color: #EBFAE5;
    flex: 1;
    min-height: 230px;
    margin-bottom: 24px;
    background-size: 400px;
    background-position: center;
    text-align: center;

    @media (max-width: 768px) {
        ${tw`w-full`}
    }
`;



const TariffHeading = styled.h3`
    ${tw`text-3xl font-bold mb-4 relative`}
    color: black;
    z-index: 2;
`;
const Card = styled.div`
    ${tw`p-8 rounded-lg shadow-lg text-center`}
    background-color: #f9fff5;
    border: 2px solid #0ABD19;
    width: 45%;
    margin: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const Highlight = styled.p`
    ${tw`text-2xl font-semibold relative`}
    color: #0ABD19;
`;

const TariffText = styled.p`
    ${tw`text-2xl font-semibold relative`}
    color: #0ABD19;
    margin: 0;
    z-index: 2;
`;

const TariffNote = styled.p`
    ${tw`text-lg font-bold mt-2 relative`}
    color: black;
    z-index: 2;
`;

const SecondTariffNote = styled.p`
    ${tw`text-lg font-bold mt-6 relative`}
    color: black;
    z-index: 2;
`;

const CostHeading = styled.h2`
    ${tw`text-center mt-4 font-black text-3xl sm:text-4xl lg:text-5xl leading-tight`}
`;
const Title = styled.h2`
    ${tw`text-2xl font-bold mb-4`}
    color: #003A60;
`;

const Subtitle = styled.p`
    ${tw`text-lg font-semibold mb-2`}
    color: #0ABD19;
`;
const Span =styled.span`
    color: #0ABD19;
`
const CenteredCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Text = styled.p`
    ${tw`text-base`}
    margin-bottom: 1rem;
`;

const Divider = styled.div`
    ${tw`flex items-center justify-center my-8`}
`;

const PlusSign = styled.div`
    ${tw`text-green-500 text-4xl font-bold`}
`;
const MainComponent = () => {
    useScript("//code.jivo.ru/widget/w8vIbKy9Jr");

    return (
        <AnimationRevealPage>
            <Header roundedHeaderButton={true}/>
            <div id="home">
                <BackgroundVideoWrapper>
                    <BackgroundVideo videoSrc="/file.mp4"></BackgroundVideo>
                </BackgroundVideoWrapper>
            </div>
            <div id="about">
                <Header2/>
            </div>
            <div id="how-it-works">
                <FeatureWithSteps
                    heading={
                        <>
                            Как это работает?
                        </>
                    }
                />
            </div>
            <Slider2 images={["Slide1"]} showDecoratorBlob={false}/>
            <Spacer/>
            <div id="goal">
                <ImageSection>
                    <BackgroundImage>
                        <TextOverlay>
                            <AimHeading>НАША ЦЕЛЬ</AimHeading>
                            <OverlayText>
                                Оказание качественных услуг, быстрая и
                            </OverlayText>
                            <OverlayText>
                                надежная доставка посылок и груза по
                            </OverlayText>
                            <OverlayText>
                                минимальной стоимости для наших клиентов
                            </OverlayText>
                        </TextOverlay>
                    </BackgroundImage>
                </ImageSection>
            </div>
            <div id="services">
                <ServicesSection>
                    <HeadingFor>Наши услуги</HeadingFor>
                    <ServiceItems>
                        <ServiceItem>
                            <ServiceImage src={Warehouse} alt="Адрес склада"/>
                            <ServiceText>Адрес склада</ServiceText>
                        </ServiceItem>
                        <ServiceItem>
                            <ServiceImage src={Receiving} alt="Получение"/>
                            <ServiceText>Получение</ServiceText>
                        </ServiceItem>
                        <ServiceItem>
                            <ServiceImage src={DeliveryS} alt="Доставка"/>
                            <ServiceText>Доставка</ServiceText>
                        </ServiceItem>
                        <ServiceItem>
                            <ServiceImage src={Customs} alt="Таможенное оформление"/>
                            <ServiceText>Таможенное оформление</ServiceText>
                        </ServiceItem>
                        <ServiceItem>
                            <ServiceImage src={Transfer} alt="Передача клиенту"/>
                            <ServiceText>Передача клиенту</ServiceText>
                        </ServiceItem>
                    </ServiceItems>
                </ServicesSection>
            </div>
            <div id="partners">
                <PartnersSection>
                    <HeadingFor>Наши партнеры</HeadingFor>
                    <Spacer/>
                    <PartnersSlider/>
                </PartnersSection>
            </div>
            <Features/>
            <CostHeading>Стоимость доставки</CostHeading>
            <Container>
                <Card>
                    <Title>Тариф</Title>
                    <Title>Фактический вес: <Span>1кг = 14$</Span></Title>
                    <Title>Объемный вес: <Span>1кг = 7$</Span></Title>
                    <Title>Минимальная партия : <Span>31кг</Span></Title>
                </Card>
                <Divider>
                    <PlusSign>+</PlusSign>
                </Divider>
                <CenteredCard>
                    <Title>Таможенная пошлина <Span>15%</Span> от стоимости товара</Title>
                </CenteredCard>

            </Container>
            <LargerSpacer/>
            <div>
                <HeadingFor>Калькулятор</HeadingFor>
                <TwoColumn>
                    <LeftColumn>
                        <TextContainer>
                            <Paragraph>
                                Заказывайте товары и груз на наш склад, при необходимости мы переупаковываем для снижения объемного веса и отправляем вам надежно упакованными
                            </Paragraph>
                            <Paragraph>
                                Онлайн-калькулятор поможет рассчитать приблизительную итоговую стоимость. В стоимость включены все услуги склада, доставка и полное таможенное оформление и платежи
                            </Paragraph>
                            <Paragraph>
                                Задайте необходимые параметры посылки и кликните на кнопку Рассчитать
                            </Paragraph>
                        </TextContainer>
                    </LeftColumn>
                    <RightColumn>
                        <Calculator/>
                    </RightColumn>
                </TwoColumn>
            </div>
            <Spacer/>
            <div align="center">
                <OverlayHeading>Вопросы-Ответы</OverlayHeading>
                <FAQComponent/>
            </div>
            <Spacer/>
            <div id="contacts">
                <ContactForm/>
            </div>
        </AnimationRevealPage>
    );
};

export default MainComponent;
