import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import emailjs from 'emailjs-com';
import logo from "images/logistics-logo.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import emailIcon from "images/icon/EmailIconForm.svg";
import clockIcon from "images/icon/ClockForm.svg";
import phoneIcon from "images/icon/IconWtform.svg";
import telegramIcon from "images/icon/telegram.svg";

const Container = styled.div`
    ${tw`relative py-16 px-4 sm:px-8 lg:px-16`}
    background-color: #f4fdf7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    margin-top: 50px;
`;

const TwoColumn = styled.div`
    ${tw`flex flex-col md:flex-row justify-between max-w-screen-xl w-full`}
`;

const Column = styled.div`
    ${tw`w-full max-w-md mx-auto md:max-w-none md:mx-0`}
`;

const FormColumn = styled(Column)`
    ${tw`md:w-6/12 flex-shrink-0 h-auto order-last md:order-last pl-4 mt-12`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
        ${tw`pl-0 items-center`}
    }
`;

const InfoColumn = styled(Column)`
    ${tw`md:w-6/12 mt-16 md:mt-0 order-first`}
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    @media (max-width: 768px) {
        ${tw`items-center text-center`}
    }
`;

const Heading = styled.div`
    ${tw`font-black text-3xl sm:text-4xl lg:text-5xl leading-tight mb-6 mt-10`}
    img {
        width: 200px;
        height: auto;
    }
    @media (max-width: 768px) {
        ${tw`mb-0`}
    }
`;

const Form = styled.form`
    ${tw`mt-8 md:mt-10 text-sm flex flex-col w-full items-center`}
    @media (max-width: 768px) {
    ${tw`mt-0`}
}
`;

const Input = styled.input`
    ${tw`py-4 px-4 rounded-lg border border-gray-300 focus:outline-none focus:border-green-500 mt-4 w-full`}
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

const Textarea = styled.textarea`
    ${tw`py-3 px-4 rounded-lg border border-gray-300 focus:outline-none focus:border-green-500 mt-10 h-24 w-full`}
    margin-bottom: 16px;
`;

const SubmitButton = styled(PrimaryButtonBase)`
    ${tw`inline-block mt-8`}
    text-decoration: none;
    border: none;
    border-radius: 10px;
    background-color: #0ABD19 !important;
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    &:hover {
        background-color: #0ABD50;
        transform: scale(1.05);
        text-decoration: none;
    }
`;

const InfoText = styled.p`
    ${tw`text-gray-900 text-lg leading-loose mb-4 flex items-center`}
    a {
        color: inherit;
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
        &:hover {
            text-decoration: underline;
        }
    }
    span {
        color: #2d2d2d;
        font-weight: 300;
        font-size: 20px;
        padding-left: 8px;
    }
`;

const ParcelAddress = styled.p`
    ${tw`text-gray-900 text-lg leading-loose mb-2 flex items-center mt-4`}
    text-align: left;
`;

const IconWrapper = styled.span`
    ${tw`mr-2 flex-shrink-0`}
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
        width: 20px;
        height: 20px;
    }
`;

const SocialIconsContainer = styled.div`
    ${tw`mt-4 flex`}
    a {
        ${tw`mx-2`}
        img {
            width: 24px;
            height: 24px;
            filter: grayscale(100%);
            transition: filter 0.3s ease;
        }
        &:hover img {
            filter: none;
        }
    }
`;

const FooterBar = styled.div`
    ${tw`w-full bg-white py-4 mt-12`}
    text-align: center;
    color: #2d2d2d;
    font-size: 1rem;
    border-top: 1px solid #e2e8f0;
`;

const ContactForm = ({
                         heading = logo,
                         submitButtonText = "Отправить",
                         formAction = "#",
                         formMethod = "get",
                     }) => {
    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        setLoading(true);

        emailjs
            .sendForm(
                "service_ulpi16m",
                "template_t61tgbf",
                e.target,
                "-FRZLCGo4lldSix3z"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Сообщение отправлено успешно!");
                    setLoading(false);
                },
                (error) => {
                    console.log(error.text);
                    alert("Произошла ошибка при отправке сообщения.");
                    setLoading(false);
                }
            );
    };

    return (
        <>
            <Container>
                <TwoColumn>
                    <InfoColumn>
                        <Heading>
                            <img src={logo} alt="logo" />
                        </Heading>
                        <InfoText>
                            <IconWrapper>
                                <img src={phoneIcon} alt="phone" />
                            </IconWrapper>
                            <a href="tel:88007777610">8 (800) 777-76-10</a>
                            <span>(бесплатно по России)</span>
                        </InfoText>
                        <InfoText>
                            <IconWrapper>
                                <img src={phoneIcon} alt="whatsapp" />
                            </IconWrapper>
                            <a href="tel:89037177631">8 903 717-76-31</a>
                            <span>(WhatsApp)</span>
                        </InfoText>
                        <InfoText>
                            <IconWrapper>
                                <img src={emailIcon} alt="email" />
                            </IconWrapper>
                            <a href="mailto:info@eparcel.ru">info@eparcel.ru</a>
                            <IconWrapper>
                                <img src={clockIcon} alt="clock" />
                            </IconWrapper>
                            <span>Пн-пт: 9:00-19:00</span>
                        </InfoText>
                        <ParcelAddress>
                            EParcel Logistics LLC <br />
                            Registration number: 5545578 <br />
                            <br />
                            Адрес склада: <br />
                            465 Carson Drive <br />
                            Bear, Delaware 19701 <br />
                            USA
                        </ParcelAddress>
                        <SocialIconsContainer>
                            <a
                                href="https://t.me/eparcelru"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={telegramIcon} alt="Telegram" />
                            </a>
                        </SocialIconsContainer>
                    </InfoColumn>
                    <FormColumn>
                        <Form action={formAction} method={formMethod} onSubmit={sendEmail}>
                            <Input type="text" name="name" placeholder="Ваше имя" required />
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email *"
                                required
                            />
                            <Textarea name="message" placeholder="Сообщение *" required />
                            <SubmitButton type="submit" disabled={loading}>
                                {loading ? "Отправка..." : submitButtonText}
                            </SubmitButton>
                        </Form>
                    </FormColumn>
                </TwoColumn>
            </Container>
            <FooterBar>© 2024 Eparcel. Все права защищены.</FooterBar>
        </>
    );
};

export default ContactForm;
