import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import Letter from "images/icon/LetterI.svg";
import IDcard from "images/icon/IDcardIcon.svg";
import Rocket from "images/icon/RocketSpeedIcon.svg";
import Boxes from "images/icon/BoxesIcon.svg";

const Container = tw.div`relative py-20`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row justify-between max-w-screen-lg mx-auto`}
`;

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full text-center`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/4 flex flex-col items-center px-4`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full px-4 py-8`}
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    margin-bottom: 12px;
    img {
      ${tw`w-20 h-20`}
    }
  }

  .textContainer {
    ${tw`flex-grow`}
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .title {
    ${tw`tracking-wide font-bold text-2xl leading-none`}
    margin-bottom: 12px;
  }

  .description {
    ${tw`font-medium text-secondary-100 leading-loose`}
    text-align: center;
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Наши преимущества", subheading = "", description = "" }) => {
  const defaultCards = [
    {
      imageSrc: Letter,
      title: "Работаем без посредников",
      description: "Прием, хранение, консолидация, доставка и выдача груза с посылками в одних руках"
    },
    {
      imageSrc: Boxes,
      title: "Любые товары",
      description: "Принимаем и доставляем любые товары ,не запрещенные к ввозу в страну"
    },
    {
      imageSrc: Rocket,
      title: "Быстрая доставка",
      description: "Доставляем грузы в течениe 15-25 дней до склада в Москве"
    },
    {
      imageSrc: IDcard,
      title: "Отсутствие деклараций",
      description: "Не требуется предоставлять данные для таможни. Оформляем ввоз товаров на свою компанию"
    }
  ];

  if (!cards) cards = defaultCards;

  return (
      <Container>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <VerticalSpacer />
        <ThreeColumnContainer>
          {cards.map((card, i) => (
              <Column key={i}>
                <Card>
                  <span className="imageContainer">
                    <img src={card.imageSrc || defaultCardImage} alt="" />
                  </span>
                  <span className="textContainer">
                    <span className="title">{card.title || "Fully Secure"}</span>
                    <p className="description">
                      {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                    </p>
                  </span>
                </Card>
              </Column>
          ))}
        </ThreeColumnContainer>

      </Container>
  );
};
